import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { DefaultLayout } from '../layouts/main-layout'
/* @ts-ignore */
import polina from '../../static/images/bp-headshots/polina.png'

const Bio = (props: {
  name: string
  position: string
  linkedInUrl: string
  photo: string
  children: React.ReactNode
}) => {
  const showLinkedIn = true
  return (
    <div className="p-4">
      <img className="hidden lg:block bio-photo mx-auto" src={props.photo} />
      <img className="block lg:hidden mx-auto" src={props.photo.replace('.jpg', '-mobile.jpg')} />
      <h3 className="text-center mt-2">{props.name}</h3>
      <p className="text-center mb-0">{props.position}</p>
      {showLinkedIn && (
        <>
          <a
            href={props.linkedInUrl}
            target="_blank"
            title={`Connect with ${props.name} on LinkedIn`}
            className="text-center block text-sky-700 text-sm no-underline font-normal  suppress-visited"
          >
            <FontAwesomeIcon icon={faLinkedin} /> Connect on Linkedin
          </a>
        </>
      )}
    </div>
  )
}

const SimpleBio = ({
  name,
  position,
  photo,
}: {
  name: string
  position: string
  linkedInUrl: string
  photo: string
}) => (
  <div className="small-bio text-center mx-2 md:mx-4 mb-4">
    <div className="small-polaroid">
      <img src={photo} title={name} alt={name} />
      <h3 className="h4 mb-0">{name}</h3>
      <p className="suppress-first-para-styling">{position}</p>
    </div>
  </div>
)

const AboutPage = () => (
  <DefaultLayout name="About" description="About BridgePhase. Our work, leadership, and contract vehicles.">
    <h1 className="page-header">About Us</h1>

    <section className="responsive-column-container">
      <h2>Who We Are</h2>
      <div className="main-content">
        <p>
          We are a small business that specializes in designing, building, and implementing digital services that help
          advance the mission of our Federal Government while preparing for new challenges ahead.
        </p>
        <p>
          We are experts in performing software development using an Agile delivery methodology, which begins with a
          user centered design approach that focuses on maximizing user value by understanding and empathizing with user
          needs. Our team iteratively builds and delivers software using a proven DevSecOps approach that consists of
          both technical and interpersonal processes which enable organizations to leverage automation, collaboration,
          and data to deliver business value faster and more securely. We also provide full life cycle implementation
          support for large scale commercial IT modernization efforts, and regularly help our clients implement powerful
          reporting and analytics capabilities to strengthen their greater competitive edge.
        </p>
        <p>
          The name "BridgePhase" was chosen because creating bridging gaps - whether writing or integrating software, or
          within an organization - represents everything we do. We build software applications which bridge together and
          integrate IT systems and solves problems for customers, starting with a design process which focuses on
          closing the gap between what end users need and what is delivered. We hire software engineers who thrive
          working in an Agile world, where we work in a constant partnership with our clients through constant
          collaboration and transparency. We also work on projects where traditional organizational silos are broken
          down, and teams are integrated under a culture of accountability, collaboration, and unwavering mission focus.
        </p>
      </div>
    </section>

    <section>
      <h2>Our Values</h2>
      <p>
        At BridgePhase, our values are more than just words on paper - they provide the foundation for our identity as a
        company, shaping our culture with what we care about most. Our values help guide everything we do, including:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <h3 className="font-normal">
            How we <em className="font-extrabold text-bp-red">Act</em>
          </h3>
          <p>
            We act with integrity, honesty, and respect in all we do, earning the trust of our employees, customers, and
            partners that establish the foundation for our collective success. We are adaptive and responsive to the
            needs of our clients, aligning with a shared purpose and a unified vision.
          </p>
        </div>
        <div>
          <h3 className="font-normal">
            How we <em className="font-extrabold text-bp-red">Think</em>
          </h3>
          <p>
            At our core, we are critical thinkers and problem solvers, leading with innovation, creativity, and
            knowledge to solve hard challenges at speed and scale. We bring diverse, out-of-the-box ideas, perspectives,
            and approaches that result in positive disruption and game-changing results for our customers.
          </p>
        </div>
        <div>
          <h3 className="font-normal">
            How we <em className="font-extrabold text-bp-red">Work</em>
          </h3>
          <p>
            We work with courage, compassion, commitment, and teamwork to deliver solutions that have lasting impact and
            sustained value. We apply disciplined engineering principles and a proven agile approach that deliver
            flexible, simplified, durable and performant architectures and platforms that drive continuous improvement.
          </p>
        </div>
        <div>
          <h3 className="font-normal">
            How we <em className="font-extrabold text-bp-red">Help</em>
          </h3>
          <p>
            We actively invest in our communities to create lasting, positive change. Through our strategic charitable
            initiatives, we not only strengthen community bonds but also empower our employees to make meaningful
            impacts in areas they're passionate about.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <img
          src={'/images/stock/company-updates.jpg'}
          alt="BridgePhase employees at a company meeting"
          title="BridgePhase company meeting"
        />
        <img
          src={'/images/stock/explaining-code.jpg'}
          alt="BridgePhase engineers during code review"
          title="BridgePhase engineers during code review"
        />
      </div>
    </section>
    <section>
      <h2>Our Leaders</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
        <Bio
          name="Matt Stratford"
          position="Chief Executive Officer"
          linkedInUrl="https://www.linkedin.com/in/matt-stratford-b350832"
          photo={'/images/headshots/matt-stratford.jpg'}
        >
          <p>
            Matt founded BridgePhase in 2008, with the vision of creating a high performing small business known for
            exceptional delivery capabilities and an employee focused company culture. As Chief Executive Officer,
            Matt’s current focus includes developing strategic partnerships, driving business development activities,
            and providing oversight into the quality of the companies work across a broad range of projects.
          </p>
        </Bio>

        <Bio
          name="Dan Belyea"
          position="Chief Operation Officer"
          linkedInUrl="https://www.linkedin.com/in/dan-belyea-408bb02"
          photo={'/images/headshots/dan-belyea.jpg'}
        >
          <p>
            As a Chief Operating Officer of BridgePhase, Dan is responsible for leading BridgePhase’s team within the
            Department of Homeland Security (DHS), where his responsibilities include providing Agile project management
            and software development support, a role he has held since 2010. Under Dan’s leadership at DHS, BridgePhase
            has consistently been recognized as top a performer in the area of software development and DevSecOps.
          </p>
        </Bio>

        <Bio
          name="JP Foley"
          position="Chief Growth Officer"
          linkedInUrl="https://www.linkedin.com/in/jp-foley-entrepreneur"
          photo={'/images/headshots/jp-foley.jpg'}
        >
          <p>
            As the Chief Growth Officer, JP is charged with further positioning the organization for its next phase of
            substantive expansion and innovation based on his proven experience in executing strategic growth
            initiatives. JP has laser-focused most of his career on cofounding, building, and exiting multiple
            award-winning GovCon firms. JP graduated with a BS degree in Management Science from the Pamplin School of
            Business at Virginia Tech, and serves on several charitable executive and advisory committees.
          </p>
        </Bio>

        <Bio
          name='Audrey "Mimi" Mensah (SHRM-CP)'
          position="Director of People Operations"
          linkedInUrl="https://www.linkedin.com/in/audmenze/"
          photo={'/images/headshots/mimi-mensah.jpg'}
        >
          <p>
            Mimi is the human resources manager for BridgePhase. She has demonstrated success with full lifecycle
            recruiting for a breadth of skillsets by identifying candidates with niche backgrounds and varying
            government clearance levels. Mimi has over 10 years of experience providing creative solutions in the
            consumer services, telecom, and government contracting industries.
          </p>
        </Bio>

        <Bio
          name="Jaime Garcia"
          position="Director of Software Development"
          linkedInUrl="https://www.linkedin.com/in/jaime-garcia-ramirez-6825909a"
          photo={'/images/headshots/jaime-garcia.jpg'}
        >
          <p>
            Jaime is a Senior Software Engineer with over 10 years of experience designing and building highly complex
            mission critical software applications, including 5+ years of experience architecting and developing open
            source applications running in AWS. As the Lead Software Engineer for BridgePhase, Jaime helps ensure
            BridgePhase maintain the highest level of software development quality and Agile engineering practices
            throughout the company.
          </p>
        </Bio>

        <Bio
          name="Kane Gyovai"
          position="Director of Engineering"
          linkedInUrl="https://www.linkedin.com/in/kane-gyovai-88221935/"
          photo={'/images/headshots/kane-gyovai.jpg'}
        >
          <p>
            Kane joined BridgePhase in 2014, and since that time has established himself as a leader in areas such as
            AWS, DevSecOps, Microservices, and Containerization. Kane has been building software across numerous
            industries, including Energy, Infrastructure, Aviation, Defense, and Homeland Security for over a decade.
            Over the years, Kane has delivered innovative technical solutions and led DevSecOps teams on multiple
            projects.
          </p>
        </Bio>

        <Bio
          name="Joe Costello"
          position="Client Delivery Executive"
          linkedInUrl="https://www.linkedin.com/in/joe-costello-6251298/"
          photo={'/images/headshots/joe-costello.jpg'}
        >
          <p></p>
        </Bio>
      </div>
    </section>
    <section>
      <h2>Project Leaders</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
        <SimpleBio
          name="Danielle Stanton"
          position="Delivery Manager"
          linkedInUrl=""
          photo={'/images/headshots/danielle-stanton-mobile.jpg'}
        />
        <SimpleBio
          name="Elaine Chan"
          position="Team Lead"
          linkedInUrl=""
          photo={'/images/headshots/elaine-chan-mobile.jpg'}
        />
        <SimpleBio
          name="Crystal Garcia"
          position="Tech Lead"
          linkedInUrl=""
          photo={'/images/headshots/crystal-garcia-mobile.jpg'}
        />
        <SimpleBio
          name="Matt Boyer"
          position="DevSecOps Engineer"
          linkedInUrl=""
          photo={'/images/headshots/matt-boyer-mobile.jpg'}
        />
        <SimpleBio
          name="Ashley Monger"
          position="Team Lead"
          linkedInUrl=""
          photo={'/images/headshots/ashley-monger-mobile.jpg'}
        />
        <SimpleBio
          name="Chris O'Connell"
          position="Senior DevSecOps Engineer"
          linkedInUrl=""
          photo={'/images/headshots/chris-oconnell.jpg'}
        />
        <SimpleBio
          name="Matt Vasquez"
          position="Senior Cloud Engineer"
          linkedInUrl=""
          photo={'/images/headshots/matt-vasquez.jpg'}
        />
        <SimpleBio
          name="Will Gangwer"
          position="Delivery Manager"
          linkedInUrl=""
          photo={'/images/headshots/will-gangwer-mobile.jpg'}
        />
        <SimpleBio
          name="Michael Lapuz"
          position="Team Lead"
          linkedInUrl=""
          photo={'/images/headshots/michael-lapuz-mobile.jpg'}
        />
      </div>
    </section>
  </DefaultLayout>
)

export default AboutPage
